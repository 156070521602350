<template>
    <a-modal :zIndex="1001" :visible="modal.visible" :centered="true" :footer="null" @cancel="onClose">
        <h5>{{modal.type == 'premium' ? 'New Lot Condition / Premium':'New Lot Grading'}}</h5>
        <a-form-model style="margin-top:24px" ref="object" :model="object">
            <a-row :gutter="16">
                <a-col :span="modal.type == 'grading' ? 24:12">
                    <a-form-model-item label="Name" required prop="name" :rules="req('Please fill in the Name')">
                        <a-input placeholder="Name" v-model="object.name"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12" v-if="modal.type != 'grading'">
                    <a-form-model-item style="width:100%" label="Default Price" prop="price">
                        <a-input-number style="width:100%" :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '')" :min="0" placeholder="Default Price" v-model="object.price"></a-input-number>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
        <div style="width:100%; margin-top:24px; text-align:right">
            <a-button @click="onClose" class="mr-3 cancel-button">CANCEL</a-button>
            <a-button @click="submit" type="primary">{{modal.type == 'premium' ? 'ADD CONDITION / PREMIUM': modal.type == 'grading' ? 'ADD GRADING' : modal.type == 'condition' ? 'ADD CONDITION' : ''}}</a-button>
        </div>
    </a-modal>
</template>

<script>
export default {
    computed:{
        modal() {
            return this.$store.state.siteplan.settingsModal
        },
        gradings() {
            if (this.$store.state.siteplan.allSettings && this.$store.state.siteplan.allSettings.app && this.$store.state.siteplan.allSettings.app.options && this.$store.state.siteplan.allSettings.app.options.gradings) return this.$store.state.siteplan.allSettings.app.options.gradings;
			return [];
        },
        premiums() {
            if (this.$store.state.siteplan.allSettings && this.$store.state.siteplan.allSettings.app && this.$store.state.siteplan.allSettings.app.options && this.$store.state.siteplan.allSettings.app.options.premiums) return this.$store.state.siteplan.allSettings.app.options.premiums;
			return [];
        },
        conditions() {
            if (this.$store.state.siteplan.allSettings && this.$store.state.siteplan.allSettings.app && this.$store.state.siteplan.allSettings.app.options && this.$store.state.siteplan.allSettings.app.options.conditions) return this.$store.state.siteplan.allSettings.app.options.conditions;
			return [];
        }
    },
    data() {
        return {
            defaultPremiums:[
                {
                    active: false,
                    id:'WOD',
                    name:'Walk-out Deck',
                    price:null
                },
                {
                    active: false,
                    id:'LOB',
                    name:'Look-out Basement',
                    price:null
                },
                {
                    active: false,
                    id:'WOB',
                    name:'Walk-out Basement',
                    price:null
                },
                {
                    active: false,
                    id:'SU',
                    name:'Side Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'RU',
                    name:'Rear Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'CU',
                    name:'Corner Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'GU',
                    name:'Gateway Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'NSU',
                    name:'No Sidewalk Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'ConU',
                    name:'Conservation Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'DAB',
                    name:'Deck At Back',
                    price:null
                },
                {
                    active: false,
                    id:'DU',
                    name:'Depth Upgrade',
                    price:null
                },
            ],
            object:{
                name:'',
                price:0,
                date:'',
                id:'',
                user:{},
                active:false
            }
        }
    },
    methods:{
        req:msg=>({required:true,message:msg}),
        submit() {
            this.$refs.object.validate(valid => {
                if (valid) {
                    if (this.modal.type == 'grading') {
                        let obj = {}
                        obj.name = this.object.name.trim()
                        obj.price = parseFloat(this.object.price)
                        obj.date = Date.now()
                        obj.id = Date.now().toString()
                        obj.user = {
                            firstName:this.$store.state.user.user.firstName,
                            lastName:this.$store.state.user.user.lastName,
                            id:this.$store.state.user.user.id
                        }
                        obj.active = false

                        let nameList = []
                        this.gradings.forEach(grad => {
                            if (!nameList.includes(grad.name.toLowerCase())) nameList.push(grad.name.toLowerCase())
                        })
                        nameList.push('easement')

                        if (nameList.includes(obj.name.toLowerCase())) return this.$message.error('This Lot Grading already exists')
                        // both gradings and premiums don't exist
                        if ((!this.gradings.length || this.gradings.length == 0) && (!this.premiums.length || this.premiums.length == 0)) {
                            this.$api.post(`/settings/:instance/siteplan`, {options:{gradings:[obj]}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Grading Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        } // only gradings exist and premiums don't
                        else if (this.gradings.length && (!this.premiums.length || this.premiums.length == 0)) {
                            let gradings = JSON.parse(JSON.stringify(this.gradings))
                            gradings.push(obj)
                            this.$api.post(`/settings/:instance/siteplan`, {options:{gradings:gradings}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Grading Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        } // both gradings and premiums exist
                        else if (this.gradings.length && this.premiums.length) {
                            let gradings = JSON.parse(JSON.stringify(this.gradings))
                            gradings.push(obj)
                            this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:this.premiums, gradings:gradings}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Grading Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        } // only premiums exist and gradings don't
                        else if ((!this.gradings.length || this.gradings.length == 0) && this.premiums.length) {
                            this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:this.premiums, gradings:[obj]}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Grading Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    } else if (this.modal.type == 'premium') {
                        let obj = {}
                        obj.name = this.object.name.trim()
                        obj.price = parseFloat(this.object.price)
                        obj.date = Date.now()
                        obj.id = Date.now().toString()
                        obj.user = {
                            firstName:this.$store.state.user.user.firstName,
                            lastName:this.$store.state.user.user.lastName,
                            id:this.$store.state.user.user.id
                        }
                        obj.active = false
                        let nameList = []
                        this.premiums.forEach(premium => {
                            if (!nameList.includes(premium.name.toLowerCase())) nameList.push(premium.name.toLowerCase())
                        })

                        if (nameList.includes(obj.name.toLowerCase())) return this.$message.error('This Lot Condition/Premium already exits')
                        // if  all premium grading and condition empty
                        if ((!this.premiums.length || this.premiums.length == 0) && (!this.gradings.length || this.gradings.length == 0)) {
                            let premiums = this.defaultPremiums
                            premiums.push(obj)
                            this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Premium/Condition Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        //if only premium exists
                        } //if only premiums exist and gradings don't
                        else if (this.premiums.length && (!this.gradings.length || this.gradings.length == 0)) {
                            let premiums = JSON.parse(JSON.stringify(this.premiums))
                            premiums.push(obj)
                            this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Premium/Condition Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        } // if both exist
                        else if (this.premiums.length && this.gradings.length) {
                            let premiums = JSON.parse(JSON.stringify(this.premiums))
                            premiums.push(obj)
                            this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums, gradings:this.gradings}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Premium/Condition Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        } // if only gradings exist and premiums don't
                        else if ((!this.premiums.length || this.premiums.length == 0) && this.gradings.length) {
                            let premiums = this.defaultPremiums
                            premiums.push(obj)
                            this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums, gradings:this.gradings}}).then(({data}) => {
                                this.$store.commit('UPDATE_SETTINGS', data)
                                this.onClose()
                                return this.$message.success("New Lot Premium/Condition Added Successfully!")
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err))
								}
							})
                        }
                    }
                }
            })
        },
        onClose() {
            this.$refs.object.resetFields()
            this.object = {
                name:'',
                price:'',
                date:'',
                id:'',
                user:{},
                active:false
            }
            this.$store.commit('CLOSE_SETTINGS_MODAL')
        }
    }
}
</script>

<style>

</style>
